<template>
  <div class="about">
    <button class="reset" @click="reset()">X</button>
    <h2>KIRA output értelmező</h2>
    <form>
      <div class="form-row">
        <div class="formfield">
          <label for="file">OUT.xml</label>
          <input
            class="form-control"
            id="file"
            type="file"
            accept=".xml"
            @change="loadTextFromFile"
          />
        </div>
      </div>
    </form>
    <div class="button-container" v-if="content">
      <button class="btn btn-primary" @click="parseFile()">
        XML feldolgozása
      </button>
    </div>
    <div class="button-container" v-if="content && xmlUnwrapped">
      <button class="btn btn-success" @click="displayErrors()">
        Hibák megmutatása
      </button>
    </div>
    <div>
      <table v-if="jogviszonyok.length" v-show="true" id="error-table">
        <thead>
          <tr>
            <th
              v-for="(header, key) in tableHeaders"
              v-bind:key="'header-' + key"
            >
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(leave, rowKey) in jogviszonyok" :key="'row-' + rowKey">
            <td>{{ leave.name }}</td>
            <td>{{ leave.taxNumber }}</td>
            <td>{{ leave.leave }}</td>
            <td>{{ leave.type }}</td>
            <td>{{ leave.allowanceType }}</td>
            <td>{{ leave.provizorCode }}</td>
            <td>{{ leave.errorMessage }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import xml2js from "xml2js";

export default {
  name: "Out",
  components: {},
  data() {
    return {
      tableHeaders: [
        "Név",
        "Adóazonosító",
        "Távollét nap",
        "Távollét KIRA kód",
        "Juttatás kód",
        "Távollét Provizor kód",
        "Hibaüzenet",
      ],
      xml: null,
      xmlUnwrapped: false,
      jogviszonyok: [],
      content: null,
      parsed: false,
    };
  },
  computed: {},
  methods: {
    reset() {
      location.reload();
    },
    loadTextFromFile(ev) {
      const file = ev.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => (this.content = e.target.result);
      reader.readAsText(file, "ISO-8859-2");
    },
    parseFile() {
      let parser = new xml2js.Parser({ explicitArray: false });
      parser
        .parseStringPromise(this.content)
        .then((result) => {
          if (Array.isArray(result["KIRA_TO_HR_TAVOLLET"]["JOGVISZONY"])) {
            this.jogviszonyok = result["KIRA_TO_HR_TAVOLLET"]["JOGVISZONY"];
          } else {
            this.jogviszonyok = [result["KIRA_TO_HR_TAVOLLET"]["JOGVISZONY"]];
          }
          this.jogviszonyok = this.jogviszonyok
            .filter(
              (jogviszony) => jogviszony["HIBAUZENET"]["UZK_AZON"] !== "0"
            )
            .map((jogviszony) => {
              const errorMessage = Array.isArray(jogviszony.TAVOLLET.HIBAUZENET)
                ? jogviszony.TAVOLLET.HIBAUZENET[0].UZK_UZENET
                : jogviszony.TAVOLLET.HIBAUZENET.UZK_UZENET;
              const row = {
                name: `${jogviszony.JV_CSNEV} ${jogviszony.JV_UNEV} ${jogviszony.JV_UNEV2}`.trim(),
                taxNumber: jogviszony.JV_SZEM_ADOAZON,
                leave: jogviszony.TAVOLLET.TVF_TKEZDETE.split("T")[0],
                type: jogviszony.TAVOLLET.TVF_JOGCIM,
                allowanceType: jogviszony.TAVOLLET.TVF_JUTTIP,
                provizorCode: jogviszony.TAVOLLET.TVF_HRAZON,
                errorMessage,
              };
              return row;
            });
        })
        .catch(function (err) {
          alert(err);
        });
    },
  },
};
</script>

<style scoped>
.about {
  position: relative;
}
.reset {
  position: absolute;
  top: 0;
  right: 0;
}
#error-table {
  font-size: 0.8rem;
}
#error-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#error-table td,
#error-table th {
  border: 1px solid #ddd;
  padding: 8px;
}

#error-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

#error-table tr:hover {
  background-color: #ddd;
}

#error-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #198754;
  color: white;
}
</style>
